import React, { useState, useEffect, useRef } from 'react';
import './SearchPage.css';
import LogoBarComponent from '../components/LogoBarComponent';
import OrderDetailComponent from '../components/OrderDetailComponent';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { TextInput, Button, Title } from '@tremor/react';
import { OrderStatus } from '../interfaces/OrderStatus';
import ReCAPTCHA from "react-google-recaptcha";

export default function SearchPage() {
  const captchaSiteKey = process.env.REACT_APP_CATCHA_SITE_KEY;
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState<string>();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [captchaResponseKey, setCaptchaResponseKey] = useState<string>();
  const [requested, setRequested] = useState<boolean>(false);

  const submitHandler = () => {
    setRequested(true);
    setSearchParams({order_id: orderId as any});
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
    axios.get(
      `${baseURL}/order/${orderId}/tracking`,
      {headers: {"captcha-response-token": captchaResponseKey}}
      )
      .then((res) => {
        console.log(res);
        setOrderStatus(res.data.status?.reverse() as OrderStatus);
        setRequested(false);
        setCaptchaResponseKey(undefined);
        recaptchaRef.current?.reset();
      })
      .catch((err) => {
        console.log(err);
        setOrderStatus(undefined);
        setRequested(false);
        setCaptchaResponseKey(undefined);
        recaptchaRef.current?.reset();
      })
  }

  useEffect(()=>{
    let queryOrderId = searchParams.get('order_id');
    if (!!queryOrderId) {
      setOrderId(queryOrderId);
    }
  },[])

  return (
    <div>
        <LogoBarComponent />

        <div className='InputSection'>
          <Title>Digite seu número de rastreio</Title>
          <TextInput
            placeholder='Digite...'
            defaultValue={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            className="m-auto mt-4"
            sitekey={captchaSiteKey}
            onChange={(value) => setCaptchaResponseKey(value)}
          />
          <Button
            className='SearchButton'
            onClick={submitHandler}
            loading={requested}
            disabled={!captchaResponseKey}  
          > Rastrear </Button>
        </div>

        {
          orderStatus ? 
          <OrderDetailComponent orderStatus={orderStatus}/>
          : ""
        }
    </div>
  );
}