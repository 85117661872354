import React from 'react';
import './OrderDetailComponent.css';
import { OrderStatus } from '../interfaces/OrderStatus';
import { Card } from '@tremor/react';
import ListEntryComponent from './ListEntryComponent';
import PurpleVector from '../images/PurpleVector.svg';
import GrayVector from '../images/GrayVector.svg';

export default function OrderDetailComponent(
    {orderStatus}:{orderStatus:OrderStatus}
    ) {

  return (
    <div className='flex mx-auto justify-center'>
        <Card className='flex max-w-3xl max-h-lg mx-4 mb-8 justify-center z-0'>
            <table className='z-10'>
            {orderStatus?.map((event)=>(
                <div className='flex'>
                    <td>
                        {orderStatus.indexOf(event) === 0 ?
                        (
                        <div className='mr-5'>
                            <div className='PurpleCircle'/>
                        </div>
                        ) :
                        (
                        <div className='mr-5'>
                            <img
                                className='my-2.5 mx-auto'
                                src={orderStatus.indexOf(event) === 1 ?
                                    PurpleVector : GrayVector }
                                alt='Arrow Up'
                            />

                            <img
                                className='mb-3 mx-auto'
                                src={orderStatus.indexOf(event) === 1 ?
                                    PurpleVector : GrayVector }
                                alt='Arrow Up'
                            />
                            <div className='GrayCircle'/>
                        </div>
                        ) }
                    </td>
                    <td>
                        <ListEntryComponent
                            event={event} first={orderStatus.indexOf(event) === 0}
                        />
                    </td>
                </div>
            ))}
            </table>
        </Card>
    </div>
  );
}