import React from 'react';
import './LogoBarComponent.css';
import RastreioLogo from '../images/RASTREIO_LOGO_3.svg' 

export default function LogoBarComponent() {
    return (
        <div className='LogoBar'>
            <div className='LogoImage'>
                <img src={RastreioLogo} alt='Rastreio Logo' />
            </div>
        </div>
    );
}