import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import SearchPage from './pages/SearchPage';

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<SearchPage />} />

          <Route path="/order" element={<SearchPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
