import React from 'react';
import './ListEntryComponent.css';
import { SingleOrderStatus } from '../interfaces/OrderStatus';
import { Title, Subtitle } from '@tremor/react';

export default function ListEntryComponent(
    {event, first}:{event:SingleOrderStatus, first:boolean}
) {
    const statusTranslate: { [key: string]: string } = {
        "created": "Pedido criado com sucesso",
        "transporter_allocated": "Preparando seu pedido",
        "collected": "Pedido coletado",
        "received": "Pedido recebido",
        "en_route": "Pedido em rota",
        "delivered": "Pedido entregue",
        "cancelled": "Pedido cancelado",
        "handling": "Pedido em tratativa",
        "awaiting_return": "Aguardando devolução do pedido",
        "awaiting_transfer": "Pedido aguardando transferência",
        "return_route": "Pedido em rota de devolução",
        "returned": "Pedido devolvido",
        "collected_not_received": "Pedido coletado mas não recebido",
        "cancelled_after_received": "Pedido cancelado após recebimento",
        "returned_area_not_serviced": "Pedido devolvido - Área não atendida",
        "new_driver_allocated": "Novo entregador alocado"
    }

    const convertDate = (date:string) => {
        const pos = date.indexOf("+");
        if (pos !== -1) {
            date = date.substring(0, pos);
        }
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - 3);
        return newDate.toLocaleString('pt-BR');
    }
    
    return (
        <div className={!first ? 'self-start pt-10' : 'self-start'}>
            <Title className='font-bold'>
                {event.type in statusTranslate ?
                statusTranslate[event.type] : event.type}
            </Title>
            <Subtitle>{convertDate(event.date)}</Subtitle>
        </div>
    );
}